import {JsonClass} from "../json_class";
export let com = {};
// Exp: 
//  com.fetchJson(url, {success: function(jsonData){...}}, error: function(response){...});
//  com.fetchJson(url, {method: 'POST', data: {}, success: function(jsonData){...}, error: function(response){...} });
//  com.fetchJson(url, {method: 'POST', data: FormData, success: function(jsonData){...}, error: function(response){...} });
com.fetchJson = function(url, option) {
    const funcName = '[fetchJson]';
    const debug = false;
    const validMethods = ['GET', 'POST'];
    const regex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/; // 正则：匹配网址
    // 获取 meta 标签的 content 值
    const versionObj = document.querySelector('meta[name="data-version"]');
    const pathObj = document.querySelector('meta[name="data-path"]');
    const constants = {
        version: (versionObj ? versionObj.getAttribute('content') : null),
        cloudPath: (pathObj ? pathObj.getAttribute('content') : ''),
    };

    if (option == null) option = {};
    if (option.cloud == null) option.cloud = false;
    if (option.method == null) option.method = 'GET';
    if (validMethods.indexOf(option.method) == -1) {
        console.warn(funcName, 'Parament option.method['+ option.method +'] error.');
        return;
    }
    if (option.success == null) option.success = function(jsonData){console.info(funcName, 'Success. jsonData ->', jsonData)};
    // if (option.error == null) option.error = function(jsonData){console.info(funcName, 'Error. jsonData ->', jsonData)};

    url = constants.version ? `${url}${url.includes('?') ? '&' : '?'}version=${constants.version}` : url;
    if (!regex.test(url) && option.cloud) {
        url = url.startsWith('/') ? url.slice(1) : url;
        url = `${constants.cloudPath}/${url}`;
    }
    if (debug) console.debug(funcName, 'url ->', url);
    if (debug) console.debug(funcName, 'option ->', option);

    let fetchOption = {
        method: option.method,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Encoding': 'gzip, deflate, br',
        }
    };
    // Get normal text file.
    if (option.type == 'text') {
        fetchOption.headers['Content-Type'] = 'text/plain; charset=utf-8';
    }
    if (option.method == 'POST') {
        // Validate option.data
        if (option.data == null || typeof(option.data) != 'object') {
            console.warn(funcName, 'Parament option.data['+ option.data +'] error.');
            return;
        }
        // Switch FormData to JSON
        let requestData = {};
        try {
            requestData = Object.fromEntries(option.data);
        } catch (e) {
            console.debug(funcName, 'Parament option.data['+ option.data +'] class is not FormData.');
            requestData = option.data;
        }
        // Get csrf token;
        const tokenName = document.querySelector('meta[name=csrf-param]');
        const tokenValue = document.querySelector('meta[name=csrf-token]');
        if (!tokenName || !tokenValue) {
            console.warn(funcName, 'Not found <meta name=csrf-param> or <meta name=csrf-token> in <head>');
            return;
        }
        requestData[tokenName.getAttribute('content')] = tokenValue.getAttribute('content');
        console.debug(funcName, 'request data->', requestData);
        fetchOption.body = JSON.stringify(requestData);
    };

    fetch(url, fetchOption)
    .then(function (response) {
        if (debug) console.debug(funcName, "response ->", response);
        if (debug) console.debug(funcName, "response status ->", response.status);
        // if (debug) console.debug(funcName, 'Error. response ->', response);

        if (!response.ok) {
            if (typeof(option.error) == 'function') {
                throw response;
            } else if (response.status === 404) {
                throw new Error('The requested JSON does not exist.[CODE: '+ response.status +']');
            } else if (response.status === 403 || response.status === 401) {
                throw new Error('Unauthorized access.[CODE: '+ response.status +']');
            } else if (response.status === 500) {
                throw new Error('System error, please contact the administrator.');
            } else if (response.status !== 200) {
                throw new Error('Bad request.[CODE: '+ response.status +']');
            } else {
                throw new Error('Network response was not ok.');
            }
        }
        // return response.json(); // Parse the response into JSON
        if (option.type == 'text') {
            return decryptResponse(response)
        } else {
            return response.json();
        }
    })
    .then(function (jsonData) {
        // Processing response json data
        if (typeof(option.success) == 'function') option.success(jsonData);

        if (debug) console.debug(funcName, 'Success. jsonData ->', jsonData);
        return;
    })
    .catch(function (error) {
        // Capture and handle errors
        if (typeof(option.error) == 'function') {
            option.error(error);
        } else {
            console.error('There was a problem with the fetch operation:\n', error.message);
        }
        return;
    });
}
function decryptResponse(response) {
    // 获取响应文本内容并解密
    let jsonClass = new JsonClass();
    return response.text().then(function (text) {

        // 使用 isValidJson 函数检查 text
        const decryptedText = isValidJson(text) ? text : jsonClass.getJson(text, true);
        // 将解密后的内容转为流
        const decodedBlob = new Blob([decryptedText], { type: 'application/json' });
        return decodedBlob.text();
    }).then(function (jsonText) {
        // 解析解密后的 JSON 内容
        return JSON.parse(jsonText);
    });
}

function isValidJson(text) {
    if (typeof text !== 'string') {
        return false;
    }
    try {
        const json = JSON.parse(text);
        // 检查 json 是否是一个对象或数组
        return (json && typeof json === 'object');
    } catch (e) {
        return false;
    }
}